<template>
  <div class="main-chart">
    <div class="chart" id="funnelChart" :style="{ height: chartHeight }"></div>
  </div>
</template>

<script>
import funnelChart_Mixins from "../mixins/funnelChart_Mixins";
export default {
  mixins: [funnelChart_Mixins],
  mounted() {
    this.initChart("funnelChart");
  },
};
</script>

<style lang="scss" scoped>
.main-chart {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .chart {
    width: 100%;
    height: 600px;
  }
}
</style>